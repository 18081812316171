
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$adtech-primary: mat.define-palette(mat.$indigo-palette);
$adtech-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$adtech-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$adtech-theme: mat.define-light-theme((
  color: (
    primary: $adtech-primary,
    accent: $adtech-accent,
    warn: $adtech-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($adtech-theme);

//@import "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200";

/* You can add global styles to this file, and also import other style files */
html, body {
  height: 100%;
  margin: 0; /* Remove default margin */
}


@font-face {
  font-family: 'DMS Sans Bold';
  src: url(./assets/fonts/DM-Sans/DMSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "DMS Sans Regular";
  src: url(./assets/fonts/DM-Sans/DMSans-Regular.ttf) format("truetype");
}

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.margin-20{
  margin:20px;
}

.margin-top-10{
  margin-top: 10px;
}

.margin-bottom-5{
  margin-bottom: 5px;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #999;
}

.m-10{
  margin: 10px;
}

.p-15{
  padding: 15px;
}

.success{
  color: green;
  font-size: 11px;
}

.error{
  color: red;
  font-size: 11px;
}

.mb-10{
  margin-bottom: 10px;
}

.navbar {
  background: #FFFFFF;
}


.collapse-header::after {
  font-family: "Material Symbols Outlined";
    content: "expand_circle_up";
    color: #333;
    top: 1px;
    right: -20px;
    position: absolute;

}

.collapse-header[aria-expanded="true"]::after {
  font-family: "Material Symbols Outlined";
  content: "expand_circle_down";
}

.listProjectHeading {
  margin-top: 32px;
  margin-bottom: 22px;
}

.filter-section .select2-container{
  width: 100% !important;
}
@media screen and (min-width: 1200px) {
.filter-section .select2-container{
  width: 300px !important;
}
}

#collapseSegemt .select2-container, #collapseEventSec .select2-container{
  width: 100% !important;
  margin-bottom: 20px;
  border-radius: 20px;
  background-color: #ccc;
}

.info-icon{
  //height: 20px;
 // width: 20px;
  margin-top: 4px;
  cursor: pointer;
}

.info{
  font-size: 10px;
}
.select2-container--default .select2-results__option--selected {
  background-color: transparent;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background: #f5f5f5;
  color: #212529;
}
.select2-container .select2-results__options[aria-multiselectable="true"] .select2-results__option {
  position: relative;
  padding-left: 36px;
  //text-transform: capitalize;
}

.select2-container .select2-selection--multiple {
  //text-transform: capitalize;
}
.select2-container .select2-results__options[aria-multiselectable="true"] .select2-results__option:before {
  width: 16px;
  height: 16px;
  border: 1px solid #ababab;
  -webkit-border-radius: 2px ;
  -moz-border-radius: 2px ;
  border-radius: 2px ;
  content: '';
  left: 18px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  z-index: 10;
}
.select2-container .select2-results__options[aria-multiselectable="true"] .select2-results__option.select2-results__option--selected:before {
  background: #ababab;
  content: "\e5ca";
  color: #ffffff;
  font-family: "Material Icons";
  line-height: 16px;
  text-align: center;
}

.fade:not(.show) {
  opacity: 0;
  display: none;
}

.hide {
  display: none;
}

.disabled{
  pointer-events: none;
}

.mat-mdc-dialog-actions {
  justify-content: end !important;
}

.dropdown-div .select2-container {
  width: 100% !important;
}

.dropdown-div .row {
  margin: 24px 0;//10px 0;
}

.border-r-20{
  border-radius: 20px !important;
}
#segmentSearch ~ .select2-container--default, #eventSearch ~ .select2-container--default{
  background-color: #D9D9D980;
  height: 30px;
  padding: 0 5px;
}
#segmentSearch ~ .select2-container--default .select2-selection--multiple, #eventSearch ~ .select2-container--default .select2-selection--multiple {
  background: transparent;
  height: 20px;
  border-radius: 20px;
  border: none;
  padding: 0;
}
#segmentSearch ~ .select2-container .select2-search--inline .select2-search__field,
#eventSearch ~ .select2-container .select2-search--inline .select2-search__field {
  color: rgba(50, 50, 50, 0.5);
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  margin-top: 0;
  padding-left: 15px;
  line-height: 17px;
}
#segmentSearch ~ .select2-container .select2-selection--multiple:after,
#eventSearch ~ .select2-container .select2-selection--multiple:after {
  color: rgba(50, 50, 50, 0.5);
}
.select2-selection--multiple:after{
  content:"";
  position:absolute;
  right:10px;
  top:15px;
  width:0;
  height:0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #888;
  cursor: pointer
 }
 .filter-section {
  margin-bottom: 10px;
}
.filter-section .demographics-filter-1 {
  margin-top: 10px;
}

 .mdc-list-item__primary-text{
  font-size: 10px !important;
  font-family: 'DMS Sans Regular' !important;
 }

 .page-link{
  cursor: pointer;
 }

 
 .lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;  
  }
  .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
  }
  @keyframes lds-ring {
  0% {
  transform: rotate(0deg);
  }
  100% {
  transform: rotate(360deg);
  }
  }
 
  #loading-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    }

.disabled {
  opacity: 0.5; /* You can adjust the opacity or add other styles */
  pointer-events: none; /* Disable pointer events */
}

.event-none{
  pointer-events: none;
}

// @media screen and (min-width: 600px) {
//   .login-form {
//     display: flex;
//     align-items: end;
//   }
//   .login-form fieldset,
//   .login-form .form-group {
//     flex: 1;
//     margin-right: 20px;
//     margin-bottom: 0;
//   }
//   }

  .login-container {  
    width: 350px;
    margin: 40px 20% 0;
    height: 507px;
    margin-left: calc(50% - 278px);
}

.login-container select {
  width: 100%;
  margin-bottom: 10px;
}

.login-container .select2-container{
  margin-bottom: 20px;
  font-family: 'DMS Sans Regular';
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.login-container .select2-container--default .select2-selection--single .select2-selection__placeholder{
  color: #495057;
}

.login-container .select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0;
}

.login-container .form-control{
  margin-bottom: 20px;
  background: #f8f9fa;
  border: none !important;
}

.login-container .select2-container--default .select2-selection--single {
  border: none;
}

.right-panel{
  height: 100vh;
}

@media screen and (min-width:768px) {
  .left-panel{
    width: 223px !important;
    flex: none !important;
    max-width: none !important;
  }
  
  .right-panel{
    width: calc(100% - 223px) !important;
    flex: none !important;
    max-width: none !important;
    // height: 100vh;
  } 
}


.btn ~ .btn {
  margin-left: 10px;
}

.action-btn{
width: 143px;
height: 40px;
top: 116px;
left: 248px;
padding: 20px 40px 21px 40px;
border: none;
border-radius: 56px !important;
gap: 8px;
background: #5472FF !important;
box-shadow: 0px 3px 12px 0px #4A3AFF2E;
}

.section-container{
width: 100%;
min-height: 194px;
top: 116px;
left: 248px;
border-radius: 10px;
background: #FFFFFF;
margin-top: 20px;
padding: 20px 30px;
}

.sec-container-title{
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  height: 18px;
  color: #323232;
  margin-bottom: 10px;
  margin-top: 20px;
}

.right-panel input.form-control {
  border: 1px solid rgba(50, 50, 50, 0.2);
  // color: rgba(50, 50, 50, 0.2);
  border-radius: 8px;
  height: 40px;
  padding: 16px;
}

.line-separator {
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, rgba(224, 225, 226, 0) 0%, #E0E1E2 49.52%, rgba(224, 225, 226, 0.15625) 99.04%);
  margin-top: 5px;
  margin-bottom: 24px;
}

.sel-btn {
  font-size: 13px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 10px;
  padding: 6px 12px;
  border: none;
  background: #fff;
  //margin: 10px 0 0 0px;
  font-family: "DMS Sans Regular" !important;
}

.active-btn {
  border: 1px solid #5B93FF;
  color: #5B93FF;
}

.selected {
  color: #5472FF;
}

.progress-high.progress-bar {
  background-color: #3A974C !important;
}

.progress-med.progress-bar{
background-color: #FFD66B !important;
}

.progress-low.progress-bar{
  background-color: #FF6A77 !important;
}

.right-panel .select2-container--default .select2-search--inline .select2-search__field {
  font-size: 14px;
}

.modal-action-btn{
  border-radius: 56px;
  background: #5472FF;
  color: #fff;
}

.modal-action-sec-btn {
background: #F2F2F2;
color: #B3B3B3;
border-radius: 56px;
}

.subscribeForFullAccessDiv .action-btn, .camp-footer .action-btn{
  color: #fff !important;
    padding: 0 10px !important;
    width: 143px;
    line-height: 32px;
    font-weight: 400 !important;
    height: 40px;
    font-size: 14px;
}

.subscribeForFullAccessDiv .action-sec-btn, .camp-footer .action-sec-btn{
  color: #B3B3B3 !important;
  padding: 0 10px !important;
  width: 141px;
  line-height: 32px;
  font-weight: 400 !important;
  height: 40px;
  font-size: 14px;
  background: #F2F2F2 !important;
  border-radius: 56px;
  border:none;
}

.mat-mdc-button   {
  --mat-mdc-button-persistent-ripple-color: none;
}
.mat-mdc-button .mdc-button__label {
  color:#B3B3B3;
}

.mdc-dialog__actions {
  min-height: 7px !important;
  padding: unset !important;
}

.text-color{
  color: #5B93FF;
}

.paddingTop5{
  padding-top: 5px;
}

.paddingTop10{
  padding-top: 10px;
}

.marginLeft15{
  margin-left: 15px;
}

.popupBtn{
  border-radius: 20px !important;
  height: 35px !important;
  /* padding-bottom: 4px !important; */
  width: 90px !important;
}

.marginTop35{
  margin-top: 35px !important;
}

.mr-6{
  margin-right: 6px;
}
.page-item .page-link {
  color: #323232CC;
}
.pagination-info, .pagination{
  font-size: 14px;
}
.pagination-arrow-l .page-link,
.pagination-arrow-r .page-link {
  width: 32px;
  height: 35px;
  padding: 6px 0;
  text-align: center;
}

.pagination-arrow-l img {
  width: 20px;
  height: auto;
  transform: rotate(90deg);  
}
.pagination-arrow-r img {
  width: 20px;
  height: auto;
  transform: rotate(270deg);  
}

.right-panel {
  height: 100%;
  min-height: 100vh;
  background: #FAFAFA;
  padding: 49px 15px 15px 15px !important;
}

.right-panel.row { 
  margin: 0;
}
@media screen and (min-width: 768px) {
.pagination-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
}

// .table th, .table td {
//   padding: 9px 15px !important;
// }

.table thead th {
  line-height: 36px;
}

.mat-mdc-button .mdc-button__label img {
  height: 18px;
}

.closeBtn:after {
  font-family: "Material Symbols Outlined";
  //content: "close";
  color: rgb(179 179 179 / 80%);
  font-size: 15px;
}

.right-panel.login-panel{
  background: #fff;
}

table{    
  thead {
    background-color: transparent;
    color: #323232;
    letter-spacing: .5px;
    font-size: 14px;

    th {
      border: none;
      font-weight: 600;
      color: #323232;
    }
  }
  tbody {
    letter-spacing: .5px;
    border: 1px solid #F0F0F0;
    tr {
      background-color: #fff;
      td {
        //font-family: 'DMS Sans Bold';
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        color: #323232;
        border-radius: 2px 0px 0px 0px;
        line-height: 36px;
        height: 56px !important;
        //padding: 5px 5px 5px 10px
      }
    }
  }
}       

.marginTop20{
  margin-top: 20px;
}

.marginTop38{
  margin-top: 38px;
}

.marginRight20{
  margin-right: 20px;
}

.paddingLeft23{
  padding-left: 23px !important;
}

.paddingLeft30{
  padding-left: 30px !important;
}

.marginTop6{
  margin-top: 6px;
}


.info-icon:after {
  color: rgb(179 179 179 / 80%);
}

.md-popup-custom-min-height{
  min-height: 174px;
}

.md-popup-aud-save{
  width: calc(100% - 87px);
  max-width: 1230px !important;
  max-height: 726px;
  height: calc(100% - 200px);
}

@media screen and (min-width:1200px) {
  .md-popup-aud-save{
    width: 1230px;
  }
}

.md-popup-aud-save .mdc-dialog__surface{
  border-radius: 30px !important;
}

.table-responsive {
  overflow: hidden;
}

.noDataClass{
  margin-left: 10px;
  color: #ff0000;
  font-weight: 700;
}

.action-sm-btn {
  width: 120px;
  height: 40px;
  top: 12px;
  left: 0;
  padding: 20px 40px 21px 40px;
  border: none;
  border-radius: 56px !important;
  gap: 8px;
  background: #5472FF !important;
  box-shadow: 0px 3px 12px 0px rgba(74, 58, 255, 0.1803921569);
  color: #fff !important;
}

.action-sm-btn .mdc-button__label{
  color: #fff;
}

.text-black{
  color: #000;
}

.col-cust-6{
  width: 45% !important;
  display: inline-block;
}

.col-cust-2{
  width: 8% !important;
  display: inline-block;
}